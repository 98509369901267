<template>
  <section>
    <div class="app-container">
      <Toolbar>
        <template v-slot:left>
          <Breadcrumb
            :home="home"
            :model="items"
            class="p-menuitem-text p-p-1"
          />
        </template>

        <template v-slot:right>
          <Button
            icon="pi pi-plus"
            class="p-mr-2 p-button-success"
            @click="openDialog"
          />
          <Button
            icon="pi pi-search"
            class="p-mr-2 p-button-primary"
            @click="openSearchForm"
          />
        </template>
      </Toolbar>
      <div class="p-mt-2">
        <DataTable
          :value="lists"
          :lazy="true"
          :scrollable="true"
          class="p-datatable-sm p-datatable-striped p-datatable-gridlines"
        >
          <Column field="employee_id" header="Associate ID"></Column>
          <Column field="employee_name" header="Associate Name"></Column>
          <Column field="branch_id" header="Store Code"></Column>
          <Column field="date" header="Date"></Column>
          <Column  header="Amount">
            <template #body="slotProps">
              {{ fixAmount(slotProps.data.amount) }}
            </template> 
          </Column>
          <Column field="reason" header="Reason"></Column>
          <Column :exportable="false" header="Action">
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-primary p-mr-2"
                @click="editIem(slotProps.data)"
              />
            </template>
          </Column>
        </DataTable>
      </div>

      <Dialog
        v-model:visible="productDialog"
        :style="{ width: '50vw' }"
        :maximizable="true"
        position="top"
        class="p-fluid"
        :closable="false"
      >
        <template #header>
          <h4 class="p-dialog-titlebar p-dialog-titlebar-icon">
            {{ dialogTitle }}
          </h4>
        </template>
        <div class="p-field">
          <label for="name">Associate PIN</label>
          <Password
            id="associateID"
            v-model.trim="product.associateID"
            required="true"
            autofocus
            :feedback="false"
            :class="{ 'p-invalid': submitted && !product.associateID }"
          />
          <small class="p-invalid" v-if="submitted && !product.associateID"
            >Associate PIN is required.</small
          >
        </div>
        <div class="p-field">
          <label for="Priority">Expense amount ($)</label>
          <InputNumber
            id="Priority"
            v-model="product.expenseAmount"
            mode="decimal"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            :useGrouping="false"
          />
        </div>
        <div class="p-field">
          <label for="Priority">Expense Reason</label>
          <InputText id="name" v-model="product.expenseReason" />
        </div>
        <template #footer>
          <Button
            type="submit"
            label="Save"
            icon="pi pi-check"
            class="p-button-primary"
            @click="saveItem"
          />
          <Button
            label="Close"
            icon="pi pi-times"
            class="p-button-danger"
            @click="closeDialogBox"
          />
        </template>
      </Dialog>
      <Dialog
    v-model:visible="searchDialog"
    :style="{ width: '50vw' }"
    :maximizable="true"
    position="top"
    class="p-fluid"
  >
    <template #header>
      <h4 class="p-dialog-titlebar p-dialog-titlebar-icon">
        Search Store Expense
      </h4>
    </template>
     <div class="p-field">
      <label for="storesList">Filter</label>
      <Dropdown
        v-model="searchForm.filter"
        :options="filterDates"
        @change="clearForFilters()"
        optionLabel="name"
      />
    </div>
    <div class="p-field">
      <label for="name">Date From:</label>
      <input
        type="date"
        v-model.trim="searchForm.date1"
        @change="ckearExpenseSearch"
        class="form-control"
      />
    </div>
    <div class="p-field">
      <label for="name">Date To:</label>
      <input
        type="date"
        v-model.trim="searchForm.date2"
        @change="ckearExpenseSearch"
        class="form-control"
      />
    </div>
    <template #footer>
      <Button
        type="submit"
        label="Search"
        icon="pi pi-search"
        class="p-button-primary"
        @click="loadList()"
      />
    </template>
  </Dialog>
    </div>
  </section>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import StoreExpense from "../../service/StoreExpense";
import Toaster from "../../helpers/Toaster";
import { IStoreExpense } from "../storeexpenses/IStoreExpense";

@Options({
  components: {},
  title: 'Store Expense'
})
export default class StoreExpenses extends Vue {
  private lists = [];
  private storeexpense;
  private productDialog = false;
  private searchDialog = false;
  private statusDialog = false;
  private totalRecords = 0;
  private limit = 0;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Store Expense" },
  ];
  private toast;

  private product: IStoreExpense = {
    id: "",
    associateID: "",
    expenseAmount: 0,
    expenseReason: "",
  };

  private filterDates = [
    { name: "None", value: "None" },
    { name: "This Day", value: "this_day" },
    { name: "This Month", value: "this_month" },
    { name: "Last Month", value: "last_month" },
    { name: "Last Three Months", value: "last_three" },
    { name: "This Year", value: "this_year" },
  ];

  private dialogTitle;
  private dialogCallback;
  private submitted = false;
  private searchForm = {
    date1: "",
    date2: "",
    filter: { name: "None", value: "None" }
  };

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.storeexpense = new StoreExpense();
    this.toast = new Toaster();
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.submitted = false;
    this.dialogTitle = "Add Store Expense";
    this.productDialog = true;
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(e) {
    e.preventDefault();

    this.submitted = true;
    if (this.product.associateID != "") {
      if (this.product.id != "") {
        this.storeexpense.updateItem(this.product).then((res) => {
          this.loadList();
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      } else {
        this.storeexpense.saveItem(this.product).then((res) => {
          this.loadList();
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      }

      this.productDialog = false;
      this.clearItem();
    }
  }

  //OPEN DIALOG BOX TO EDIT
  editIem(data) {
    this.submitted = false;
    this.dialogTitle = "Edit Store Expense";
    this.productDialog = true;

    this.storeexpense.getItem(data).then((res) => {
      if (res) {
        this.product = {
          id: res.id,
          associateID: '',
          expenseAmount: res.amount,
          expenseReason: res.reason,
        };
      }
    });
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList();
  }

  //FETCH THE DATA FROM SERVER
  loadList() {
    this.searchDialog = false;
    this.storeexpense.getItems(this.searchForm).then((data) => {
      this.lists = data.expense_list;
    });
  }

  clearItem() {
    this.product = {
      id: "",
      associateID: "",
      expenseAmount: 0,
      expenseReason: "",
    };
  }

  //CLOSE THE ITEM DAILOG BOX
  closeDialogBox() {
    this.productDialog = false;
    this.submitted = false;
    this.clearItem();
  }

   openSearchForm() {
    this.searchDialog = true;

    // this.registerService.getBranchBasic().then((data) => {
    //   this.myStores = data.branches_list;
    // });
  }

  clearForFilters() {
    this.searchForm.date1 = "";
    this.searchForm.date2 = "";
  }

  ckearExpenseSearch() {
    this.searchForm.filter = { name: "None", value: "None" };
  }

  fixAmount(amt)
  {
    let total = 0;

    if(amt != null)
    {
      total = amt.toFixed(2);
    }

    return total;
  }
}
</script>

<style scoped>
.product-image {
  width: 30px;
  height: auto;
}
</style>
