
<template>
  <section>
    <div class="app-container">
      <Toolbar>
        <template v-slot:left>
          <Breadcrumb
            :home="home"
            :model="items"
            class="p-menuitem-text p-p-1"
          />
        </template>

        <template v-slot:right>
          <div class="p-inputgroup">
            <InputText
              v-model.trim="keyword"
              required="true"
              :class="{ 'p-invalid': submitted && !keyword }"
              placeholder="Search"
            />
            <Button
              icon="pi pi-search "
              class="p-button-primary p-mr-1"
              @click="loadSearchData"
            />
          </div>
          <div class="p-mx-2">
            <Button
              icon="pi pi-plus"
              class="p-button-success"
              @click="openDialog"
            />
          </div>
        </template>
      </Toolbar>
      <div class="p-mt-2">
        <DataTable
          :value="lists"
          :lazy="true"
          :loading="loading"
          :paginator="checkPagination"
          :rows="limit"
          :totalRecords="totalRecords"
          :scrollable="true"
          @page="onPage($event)"
          class="p-datatable-sm p-datatable-striped p-datatable-gridlines"
        >
          <template #empty>
            <div class="p-text-center p-p-3">No records found</div>
          </template>
          <Column field="code" header="Store Code"></Column>
          <Column field="mall" header="Store Name"></Column>
          <Column field="dry_name" header="Dry Cleaner"></Column>
          <Column field="region_name" header="Region"></Column>
          <Column field="percentage" header="Percentage"></Column>
          <Column field="created_date" header="Created"></Column>
          <Column field="branch_status" header="Status"></Column>
          <Column :exportable="false" header="Action">
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-primary p-mr-2"
                @click="editIem(slotProps.data)"
              />
              <Button
                v-if="slotProps.data.branch_status == 'Active'"
                icon="pi pi-eye-slash"
                class="p-button-rounded p-button-danger"
                @click="confirmChangeStatus(slotProps.data, 'Inactive')"
              />
              <Button
                v-else-if="slotProps.data.branch_status == 'Inactive'"
                icon="pi pi-eye"
                class="p-button-rounded p-button-warning"
                @click="confirmChangeStatus(slotProps.data, 'Active')"
              />
            </template>
          </Column>
        </DataTable>
      </div>

      <Dialog
        v-model:visible="productDialog"
        :style="{ width: '50vw' }"
        :maximizable="true"
        position="top"
        class="p-fluid"
      >
        <template #header>
          <h4 class="p-dialog-titlebar p-dialog-titlebar-icon">
            {{ dialogTitle }}
          </h4>
        </template>
        <div class="p-field">
          <label for="itemName">Stores:</label>
          <Dropdown
            v-model="product.storeId"
            :options="allBranch"
            :filter="true"
          >
            <template #value="slotProps">
              <span>
                Store Code {{ slotProps.value.code }} | Mall
                {{ slotProps.value.mall }} | Item Name
                {{ slotProps.value.country }} | Province
                {{ slotProps.value.province_name }} | Region
                {{ slotProps.value.region_name }}
              </span>
            </template>
            <template #option="slotProps">
              <span>
                Store Code {{ slotProps.option.code }} | Mall
                {{ slotProps.option.mall }} | Item Name
                {{ slotProps.option.country }} | Province
                {{ slotProps.option.province_name }} | Region
                {{ slotProps.option.region_name }}
              </span>
            </template>
          </Dropdown>
        </div>

        <div class="p-field">
          <label for="itemName">Dry Cleaner:</label>
          <Dropdown
            v-model="product.dryCleanerId"
            :options="allDryCleanerList"
            :filter="true"
          >
            <template #value="slotProps">
              <span>
                Name {{ slotProps.value.name }} | Country
                {{ slotProps.value.country }} | Province
                {{ slotProps.value.province_name }}
                {{ slotProps.value.country }} | Region
                {{ slotProps.value.region_name }}
              </span>
            </template>
            <template #option="slotProps">
              <span>
                Name {{ slotProps.option.name }} | Country
                {{ slotProps.option.country }} | Province
                {{ slotProps.option.province_name }}
                {{ slotProps.option.country }} | Region
                {{ slotProps.option.region_name }}
              </span>
            </template>
          </Dropdown>
        </div>

        <template #footer>
          <Button
            type="submit"
            label="Assign Dry Cleaner"
            icon="pi pi-check"
            class="p-button-primary"
            @click="saveItem"
          />
        </template>
      </Dialog>
      <Dialog
        v-model:visible="statusDialog"
        :style="{ width: '450px' }"
        header="Confirm"
      >
        <div class="confirmation-content">
          <i
            class="pi pi-exclamation-triangle p-mr-3"
            style="font-size: 2rem"
          />
          <span v-if="product">
            Change the status to
            <b> {{ productStatus }}? </b></span
          >
        </div>
        <template #footer>
          <Button
            label="No"
            icon="pi pi-times"
            class="p-button-text"
            @click="statusDialog = false"
          />
          <Button
            label="Yes"
            icon="pi pi-check"
            class="p-button-text"
            @click="changeStatus"
          />
        </template>
      </Dialog>
    </div>
  </section>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import StoreDryCleaner from "../../service/StoreDryCleaner";
import Toaster from "../../helpers/Toaster";

@Options({
  components: {},
})
export default class StoreDryCleaners extends Vue {
  private imagePath = "";
  private lists = [];
  private storeDryCleaner;
  private allDryCleanerList;
  private allBranch;
  private productStatus = "";
  private keyword = "";
  private loading = false;
  private productDialog = false;
  private statusDialog = false;
  private checkPagination = true;
  private totalRecords = 0;
  private limit = 0;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Assign Dry Cleaner" },
  ];

  private sectionObj = [
    { key: "section_1", value: "Section 1" },
    { key: "section_2", value: "Section 2" },
  ];

  private product = {
    id: 0,
    storeId: {},
    dryCleanerId: {},
    status: "",
  };

  private dialogTitle;
  private dialogCallback;
  private submitted = false;

  private selectedDryCleanerId = "";
  private selectedDryCleanerObj = {};

  private selectedBranchId = "";
  private selectedBranchObj = {};

  private toast;

  //CALLING WHEN PAGINATION BUTTON CLICKS
  onPage(event) {
    this.loadList(event.first);
  }

  // CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList(0);
  }

  created() {
    this.storeDryCleaner = new StoreDryCleaner();
    this.imagePath = this.storeDryCleaner.getBaseURL() + "uploads/services/";
    this.toast = new Toaster();
  }

  setDropDownData() {
    this.allBranch.filter((elem) => {
      if (elem.code == this.selectedBranchId) {
        this.selectedBranchObj = elem;
      }
    });

    this.allDryCleanerList.filter((elem) => {
      if (elem.dry_clearner_id == this.selectedDryCleanerId) {
        this.selectedDryCleanerObj = elem;
      }
    });
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.product = {
      id: 0,
      storeId: {},
      dryCleanerId: {},
      status: "",
    };

    this.submitted = false;
    this.dialogTitle = "Assign Stores";
    this.productDialog = true;
  }

  //CLOSE THE ITEM DAILOG BOX
  hideDialog() {
    this.productDialog = false;
    this.submitted = false;
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(e) {
    e.preventDefault();
    this.submitted = true;
    if (this.product.storeId) {
      if (this.product.id != 0) {
        this.storeDryCleaner.updateItem(this.product).then((res) => {
          this.loadList(0);
          this.toast.handleResponse(res);
        });
      } else {
        this.storeDryCleaner.saveItem(this.product).then((res) => {
          this.loadList(0);
          this.toast.handleResponse(res);
        });
      }

      this.productDialog = false;
      this.product = {
        id: 0,
        storeId: {},
        dryCleanerId: {},
        status: "",
      };
    }
  }

  // OPEN DIALOG BOX TO EDIT
  editIem(data) {
    this.submitted = false;
    this.dialogTitle = "Update Store Dry Cleaner";
    this.productDialog = true;

    this.storeDryCleaner.getItem(data).then((res) => {
      if (res.length > 0) {
        this.selectedBranchId = res[0].branch_id;
        this.selectedDryCleanerId = res[0].dry_clearner_id;
        this.setDropDownData();

        this.product = {
          id: res[0].assign_branch_cleaner_id,
          storeId: this.selectedBranchObj,
          dryCleanerId: this.selectedDryCleanerObj,
          status: res[0].branch_status,
        };
      }
    });
  }

  //OPEN DIALOG BOX FOR CONFIRMATION
  confirmChangeStatus(data, status) {
    this.product = {
      id: data.assign_branch_cleaner_id,
      storeId: {},
      dryCleanerId: {},
      status: data.branch_status,
    };

    this.productStatus = status;
    this.statusDialog = true;
  }

  //CHANGE THE STATUS AND SEND HTTP TO SERVER
  changeStatus() {
    this.statusDialog = false;
    this.product.status = this.productStatus;
    this.storeDryCleaner.changeStatus(this.product).then((res) => {
      this.loadList(0);
      this.toast.handleResponse(res);
    });
  }

  //FETCH THE DATA FROM SERVER
  loadList(page) {
    this.loading = true;
    this.storeDryCleaner.getItems(page).then((data) => {
      this.lists = data.dry_cleaners;
      this.totalRecords = data.total_store_count;
      this.limit = data.limit_per_page;
      this.allDryCleanerList = data.dry_clearners_list;
      this.allBranch = data.branches;
      this.loading = false;
    });
  }

  loadSearchData() {
    this.submitted = true;
    if (this.keyword) {
      this.storeDryCleaner
        .getSearchedStoreDryCleaners(this.keyword)
        .then((data) => {
          this.lists = data.dry_cleaners;
          this.allDryCleanerList = data.dry_clearners_list;
          this.allBranch = data.branches;
          this.checkPagination = data.pagination;
        });
    }
  }
}
</script>

<style scoped>
.product-image {
  width: 30px;
  height: auto;
}
</style>